import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.scss';

const Footer = () => {
    const { user, editUser } = useSelector((state) => state.user);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        initMarquee(800, 60)
    })

    function initMarquee(boxWidth, time) {
        const boxElement = document.querySelectorAll('.landing-container-footer-content-marquee-text');
        const boxLength = boxElement.length;
        const wrapperWidth = boxWidth * boxLength;
        const windowWidth = window.innerWidth;

        boxElement.forEach((el) => {
            el.parentNode.style.left = '-' + boxWidth + 'px';
            el.style.width = boxWidth + 'px'; 
        })

        gsap.set(".landing-container-footer-content-marquee-text", {
            x: (i) => i * boxWidth
        });

        gsap.to(".landing-container-footer-content-marquee-text", {
            duration: time,
            ease: "none",
            x: "-=" + wrapperWidth,
            modifiers: {
                x: gsap.utils.unitize(
                    function (x) {
                        return parseFloat(x + windowWidth + boxWidth) % wrapperWidth
                    }
                )
            },
            repeat: -1
        });
    }

    return (
        <section className="landing-container-footer">
            <div className="landing-container-footer-content">

                <div className="test">
                    <div className="landing-container-footer-content-marquee">
                        <p className="landing-container-footer-content-marquee-text">récoltez davantage avis Google<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">fidélisez votre clientèle<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">améliorez votre expérience client<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">récoltez davantage avis Google<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">fidélisez votre clientèle<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">améliorez votre expérience client<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">récoltez davantage avis Google<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">fidélisez votre clientèle<span class="landing-container-footer-content-marquee-star">✦</span></p>
                        <p className="landing-container-footer-content-marquee-text">améliorez votre expérience client<span class="landing-container-footer-content-marquee-star">✦</span></p>
                    </div>
                </div>


                <ul className="landing-container-footer-content-top">
                    <li className="landing-container-footer-content-top-row">
                        <h6 className="landing-container-footer-content-top-row-title">Produit</h6>
                        <Link to={"/prix"} className="landing-container-footer-content-top-row-item">Prix</Link>
                        {/* <Link to={"/"} className="landing-container-footer-content-top-row-item">Démo</Link> */}
                        <Link to={"/fonctionnalites"} className="landing-container-footer-content-top-row-item">Fonctionnalités</Link>
                    </li>
                    <li className="landing-container-footer-content-top-row">
                        <h6 className="landing-container-footer-content-top-row-title">Ressources</h6>
                        <Link to={"/contact"} className="landing-container-footer-content-top-row-item">Aide & contact</Link>
                        {/* <p className="landing-container-footer-content-top-row-item">Blog</p> */}
                    </li>
                    <li className="landing-container-footer-content-top-row">
                        <h6 className="landing-container-footer-content-top-row-title">Politique</h6>
                        <Link to={"/conditions-generales-de-vente"} className="landing-container-footer-content-top-row-item">Conditions Générales de Vente</Link>
                        <Link to={"/conditions-generales-utilisation"} className="landing-container-footer-content-top-row-item">Conditions Générales d'Utilisation</Link>
                        <Link to={"/politique-de-confidentialite"} className="landing-container-footer-content-top-row-item">Politique de confidentialité</Link>
                        <Link to={"/politique-de-remboursement"} className="landing-container-footer-content-top-row-item">Politique de remboursement</Link>
                        <Link to={"/mentions-legales"} className="landing-container-footer-content-top-row-item">Mentions légales</Link>
                    </li>
                </ul>

                <div className="landing-container-footer-content-bottom">
                    <div className="landing-container-footer-content-bottom-left">© Venco 2024 | tout droit réservé</div>
                    <div className="landing-container-footer-content-bottom-right"></div>
                </div>
                
            </div>
        </section>
    )
}

export default Footer