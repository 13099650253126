import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Route, Routes, useLocation, Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import { fetchPlaceBasics, fetchPlaceUi } from '../../utils';
import ClientTopHeader from "../../components/ClientTopHeader/ClientTopHeader"


import './style.scss';

const ClientLanding = () => {
    const { currentPlace, editCurrentPlace } = useSelector((state) => state.client);

    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let { id } = useParams()
    const dispatch = useDispatch();
    const [currentLocationUi, setcurrentLocationUi] = useState({});

    const handleStop = (event, dragElement) => {
        let barWidth = document.querySelector('.landing-bottom-drag-parent').offsetWidth - 50;
        if(barWidth == dragElement.x) {
            navigate("has-review");
        }
    };

    const fetchPlace = async () => {
        await fetchPlaceBasics(dispatch, `/places/get-place-basics/${id}`);
        setLoading(false)
    };
    useEffect(() => {
        if (Object.keys(currentPlace).length == 0) {
            setLoading(true)
            fetchPlace();
        }
    }, [])

    const toggleFetchPlaceUi = async (id) => {
        var placeUi = await fetchPlaceUi(dispatch, id);
        setcurrentLocationUi(placeUi);
        setLoading(false)
    };
    useEffect(() => {
        if(Object.keys(currentPlace).length !== 0 && currentPlace.customUi.length !== 0) {
            setLoading(true)
            toggleFetchPlaceUi(currentPlace.customUi);
        }
    }, [currentPlace])
    
    if (currentPlace?.status !== "live") {
        console.log("oui");
        return (
            <div className="container" style={{"background": "#f3f3f3", "width": "100svw", "height": "100svh", "display": "flex", "justify-content": "center", "align-items": "center"}}>
                <h1>Désolé cette page n'est pas disponible</h1>
            </div>
        )
    }
    return (
        <div className={`landing`}>
            <div className="landing-top" style={{backgroundImage: `url('${currentPlace?.image}'`}}>
                <ClientTopHeader />
            </div>
            <div className="landing-bottom">
                <div className="landing-bottom-content">
                    <h1>{currentLocationUi?.landingTitle ? currentLocationUi?.landingTitle : "Recevez une récompense pour votre avis !"}</h1>
                    <h4>{currentLocationUi?.landingDescription ? currentLocationUi?.landingDescription : "profitez d'une récomponse excepetionnelle lors de votre prochaine commande."}</h4>
                </div>
                <div className="landing-bottom-drag-parent">
                    <h6 className="landing-bottom-drag-parent-title">Swipe pour commencer</h6>
                    <Draggable 
                        axis='x'
                        handle='.circle'
                        bounds="parent"
                        position={{x: 0, y: 0}}
                        onDrag={handleStop} 
                    >
                        <div className="circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="white">
                                <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </Draggable>
                </div>
            </div>
        </div> 
    )
}

export default ClientLanding;