import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { ToggleMenuOppened, ToggleLoading } from '../../redux/globalSlice';
import { getUserInfo, fetchPlaces, fetchRewards } from '../../utils';
import { Logout } from '../../redux/userSlice';
import { UserLogin } from '../../redux/userSlice';
import './style.scss';

import logo from "./svg/logo.png"

const LeftNav = () => {
    const { user, editUser } = useSelector((state) => state.user);
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const { loading } = useSelector((state) => state.global);
    const location = useLocation();
    const [isUserAllin, setisUserAllin] = useState(true);

    const dispatch = useDispatch();

    const handleClickToggle = () => {
        dispatch(ToggleMenuOppened());
        if (document.querySelector('.left-nav-bottom-panel').classList.contains("visible")) {
            document.querySelector('.left-nav-bottom-panel').classList.remove("visible")
        }
    }

    const getUser = async () => {
        const res = await getUserInfo(user?.token);
  
        console.log(res);
        console.log(typeof res);
        if (typeof res !== "undefined") {
          const newData = { token: user?.token, ...res };
          dispatch(UserLogin(newData));
        }
    };
    const fetchAllPlaces = async () => {
        await fetchPlaces(user?.token, dispatch, "places/get-places");
    };
    const fetchAllRewards = async () => {
        await fetchRewards(user?.token, dispatch, "rewards/get-rewards");
        dispatch(ToggleLoading(false));
    };

    const handleUserClick = () => {
        if (menuOppened) {
            document.querySelector('.left-nav-bottom-panel').classList.toggle("visible")
        }
    };

    const handleMobileMenuClose = (e) => {
        if (window.innerWidth <= 1024 && menuOppened) {
            dispatch(ToggleMenuOppened())
            if (e.target.classList.contains("left-nav-bottom-panel-item")) {
                document.querySelector('.left-nav-bottom-panel').classList.toggle("visible")
            }
        }
    }

    const handleUserRights= () => {
        if (user?.stripeProductId !== "prod_PrwZQg2iXCJo9h") {
            setisUserAllin(false)
        };
    };

    useEffect(() => {
        if (typeof user?.token !== "undefined" && user?.stripeSubscriptionId) {
            dispatch(ToggleLoading(true));
            getUser();
            fetchAllPlaces();
            fetchAllRewards();
        }
        handleUserRights();
    }, [])

    return (
        <div className={`left-nav ${menuOppened ? "oppend" : "closed"}`}>
            <div className="left-nav-top">
                {menuOppened && (<Link to={"/dashboard"} className="left-nav-top-logo">
                    <img src={logo} alt="" />
                </Link>)}
                <span className="left-nav-top-toggle" onClick={() => { handleClickToggle() }}>
                    {menuOppened && <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4V20M8 12H20M8 12L12 8M8 12L12 16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                    {!menuOppened && <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4V20M8 12H20M20 12L16 8M20 12L16 16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                </span>
            </div>
            <div className="left-nav-menu">
                <h2 className="left-nav-menu-title">{menuOppened && "Menu principal"}</h2>
                <ul className="left-nav-menu-items">
                    <NavLink to="/dashboard" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M359.168 768H160a32 32 0 0 1-32-32V192H64a32 32 0 0 1 0-64h896a32 32 0 1 1 0 64h-64v544a32 32 0 0 1-32 32H665.216l110.848 192h-73.856L591.36 768H433.024L322.176 960H248.32l110.848-192zM832 192H192v512h640V192zM342.656 534.656a32 32 0 1 1-45.312-45.312L444.992 341.76l125.44 94.08L679.04 300.032a32 32 0 1 1 49.92 39.936L581.632 524.224 451.008 426.24 342.656 534.592z"/></svg>
                            {menuOppened && "Dashboard"}
                        </li>
                    </NavLink>
                    <NavLink to="/lieux" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9.5C5 6.09371 8.00993 3 12 3C15.9901 3 19 6.09371 19 9.5C19 11.6449 17.6877 14.0406 15.9606 16.2611C14.5957 18.016 13.0773 19.5329 12 20.5944C10.9227 19.5329 9.40427 18.016 8.03935 16.2611C6.31229 14.0406 5 11.6449 5 9.5ZM12 1C6.99007 1 3 4.90629 3 9.5C3 12.3551 4.68771 15.2094 6.46065 17.4889C7.99487 19.4615 9.7194 21.1574 10.7973 22.2173C10.9831 22.4001 11.1498 22.564 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23C12.2652 23 12.5196 22.8946 12.7071 22.7071C12.8502 22.564 13.0169 22.4001 13.2027 22.2174L13.2028 22.2173C14.2806 21.1573 16.0051 19.4615 17.5394 17.4889C19.3123 15.2094 21 12.3551 21 9.5C21 4.90629 17.0099 1 12 1ZM12 12.5C13.3807 12.5 14.5 11.3807 14.5 10C14.5 8.61929 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.61929 9.5 10C9.5 11.3807 10.6193 12.5 12 12.5Z" fill="#000000"/>
                            </svg> 
                            {menuOppened && "Lieux"}
                        </li>
                    </NavLink>
                    <NavLink to="/recompenses" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 17H21M12 8L10 12M12 8L14 12M12 8H7.5C6.83696 8 6.20107 7.73661 5.73223 7.26777C5.26339 6.79893 5 6.16304 5 5.5C5 4.83696 5.26339 4.20107 5.73223 3.73223C6.20107 3.26339 6.83696 3 7.5 3C11 3 12 8 12 8ZM12 8H16.5C17.163 8 17.7989 7.73661 18.2678 7.26777C18.7366 6.79893 19 6.16304 19 5.5C19 4.83696 18.7366 4.20107 18.2678 3.73223C17.7989 3.26339 17.163 3 16.5 3C13 3 12 8 12 8ZM6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H6.2C5.0799 8 4.51984 8 4.09202 8.21799C3.71569 8.40973 3.40973 8.71569 3.21799 9.09202C3 9.51984 3 10.0799 3 11.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {menuOppened && "Récompenses"}
                        </li>
                    </NavLink>
                    <NavLink to="/analyse" className={`left-nav-menu-item-analyse ${!isUserAllin ? "premium-feature" : ""}`} onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="invisible_box" data-name="invisible box">
                                    <rect width="48" height="48" fill="none"/>
                                    </g>
                                    <g id="icons_Q2" data-name="icons Q2">
                                    <path d="M46,9c0-6.8-19.7-7-22-7S2,2.2,2,9V39c0,6.8,19.7,7,22,7s22-.2,22-7V9.3h0Zm-4,9.8C41,20,34.4,22,24,22S7,20,6,18.8V13.4C11.9,15.9,22.4,16,24,16s12.1-.1,18-2.6Zm0,10C41,30,34.4,32,24,32S7,30,6,28.8V23.4C11.9,25.9,22.4,26,24,26s12.1-.1,18-2.6ZM24,6c9.8,0,16.3,1.8,17.8,3-1.5,1.2-8,3-17.8,3S7.7,10.2,6.2,9C7.7,7.8,14.2,6,24,6Zm0,36C13.6,42,7,40,6,38.8V33.4C11.9,35.9,22.4,36,24,36s12.1-.1,18-2.6v5.4C41,40,34.4,42,24,42Z"/>
                                    </g>
                                </g>
                            </svg>
                            {menuOppened && "Données client"} {!isUserAllin && menuOppened ? " (fonctionnalité all-in)" : ""}
                        </li>
                    </NavLink>
                    <NavLink to="/boutique" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {menuOppened && "Boutique"}
                        </li>
                    </NavLink>
                    <NavLink to="/scan" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 4C23 2.34315 21.6569 1 20 1H16C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V4Z" fill="#0F0F0F"/>
                                <path d="M23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23H20C21.6569 23 23 21.6569 23 20V16Z" fill="#0F0F0F"/>
                                <path d="M4 21C3.44772 21 3 20.5523 3 20V16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16V20C1 21.6569 2.34315 23 4 23H8C8.55228 23 9 22.5523 9 22C9 21.4477 8.55228 21 8 21H4Z" fill="#0F0F0F"/>
                                <path d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8V4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1H4C2.34315 1 1 2.34315 1 4V8Z" fill="#0F0F0F"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6C11 5.44772 10.5523 5 10 5H6C5.44772 5 5 5.44772 5 6V10C5 10.5523 5.44772 11 6 11H10C10.5523 11 11 10.5523 11 10V6ZM9 7.5C9 7.22386 8.77614 7 8.5 7H7.5C7.22386 7 7 7.22386 7 7.5V8.5C7 8.77614 7.22386 9 7.5 9H8.5C8.77614 9 9 8.77614 9 8.5V7.5Z" fill="#0F0F0F"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13C18.5523 13 19 13.4477 19 14V18C19 18.5523 18.5523 19 18 19H14C13.4477 19 13 18.5523 13 18V14C13 13.4477 13.4477 13 14 13H18ZM15 15.5C15 15.2239 15.2239 15 15.5 15H16.5C16.7761 15 17 15.2239 17 15.5V16.5C17 16.7761 16.7761 17 16.5 17H15.5C15.2239 17 15 16.7761 15 16.5V15.5Z" fill="#0F0F0F"/>
                                <path d="M14 5C13.4477 5 13 5.44772 13 6C13 6.55229 13.4477 7 14 7H16.5C16.7761 7 17 7.22386 17 7.5V10C17 10.5523 17.4477 11 18 11C18.5523 11 19 10.5523 19 10V6C19 5.44772 18.5523 5 18 5H14Z" fill="#0F0F0F"/>
                                <path d="M14 8C13.4477 8 13 8.44771 13 9V10C13 10.5523 13.4477 11 14 11C14.5523 11 15 10.5523 15 10V9C15 8.44772 14.5523 8 14 8Z" fill="#0F0F0F"/>
                                <path d="M6 13C5.44772 13 5 13.4477 5 14V16C5 16.5523 5.44772 17 6 17C6.55229 17 7 16.5523 7 16V15.5C7 15.2239 7.22386 15 7.5 15H10C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13H6Z" fill="#0F0F0F"/>
                                <path d="M10 17C9.44771 17 9 17.4477 9 18C9 18.5523 9.44771 19 10 19C10.5523 19 11 18.5523 11 18C11 17.4477 10.5523 17 10 17Z" fill="#0F0F0F"/>
                            </svg>
                            {menuOppened && "Scanner"}
                        </li>
                    </NavLink>
                    <NavLink to="/support" onClick={(e) => {handleMobileMenuClose(e)}}>
                        <li className="left-nav-menu-item">
                            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 10C9 9.40666 9.17595 8.82664 9.50559 8.33329C9.83524 7.83994 10.3038 7.45543 10.852 7.22836C11.4001 7.0013 12.0033 6.94189 12.5853 7.05765C13.1672 7.1734 13.7018 7.45912 14.1213 7.87868C14.5409 8.29824 14.8266 8.83279 14.9424 9.41473C15.0581 9.99667 14.9987 10.5999 14.7716 11.1481C14.5446 11.6962 14.1601 12.1648 13.6667 12.4944C13.1734 12.8241 12.5933 13 12 13V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <circle cx="12" cy="17" r="1" fill="#000000"/>
                            </svg>
                            {menuOppened && "Support"}
                        </li>
                    </NavLink>
                </ul>
            </div>
            {/* <div className="left-nav-bottom" onClick={() => dispatch(Logout())}> */}
            <div className="left-nav-bottom" >
                <div className="left-nav-bottom-panel">
                    <ul className='left-nav-bottom-panel-items'>
                        <Link className="left-nav-bottom-panel-item" to={`/profile/${user._id}`} onClick={(e) => {handleMobileMenuClose(e)}} >Modifier mon profil</Link>
                        <li className="left-nav-bottom-panel-item" onClick={() => dispatch(Logout())}>Se déconnecter</li>
                    </ul>
                </div>
                {!menuOppened && <Link to={`/profile/${user._id}`} className="left-nav-bottom-account">
                    <img src={user?.profileUrl ? user?.profileUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="profile" className="left-nav-bottom-account-img" />
                </Link>}
                {menuOppened && <div className="left-nav-bottom-account" onClick={() => {handleUserClick()}}>
                    <img src={user?.profileUrl ? user?.profileUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="profile" className="left-nav-bottom-account-img" />
                    <div className="left-nav-bottom-account-content">
                        <p className="left-nav-bottom-account-content-name">{user.firstName + " " + user.lastName}</p>
                        <p className="left-nav-bottom-account-content-role">{user?.stripeProductName ? user?.stripeProductName : "Free user"}</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default LeftNav