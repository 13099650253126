import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    places: {},
};

const placeSlice = createSlice({
    name: "place",
    initialState,
    reducers: {
        getPlaces(state, action) {
            state.places = action.payload;
        },
    }
}) 

export default placeSlice.reducer;

export function SetPlaces(places) {
    return (dispatch, getState) => {
        dispatch(placeSlice.actions.getPlaces(places));
    }
}