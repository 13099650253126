import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { set, useForm } from "react-hook-form";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import TextareaInput from '../../components/TextareaInput/TextareaInput';
import axios from 'axios';

import './style.scss';

const LegalConfi = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | Politique de confidentialité",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }
    
    return (
        <div className='landing_legal'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_legal-container">

                <div className="landing_legal-container-content">

                    <h1 className="landing_legal-container-content-title">Politique de confidentialité</h1>

                    <ol>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Collecte de données</h2>
                            <p className="landing_legal-container-content-content">Venco collecte les données suivantes : utilisateurs : email, nom, prenom ; clients ayant laissé un avis : nom, prénom, email, note et avis.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Utilisation des données</h2>
                            <p className="landing_legal-container-content-content">Venco utilise les données des clients ayant laissé un avis uniquement pour les rendre disponibles dans le dashboard des Clients de Venco. Les données collectées sont utilisées pour améliorer les services et offrir une expérience personnalisée aux utilisateurs.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Partage des données</h2>
                            <p className="landing_legal-container-content-content">Les données ne sont pas partagées avec des tiers, sauf si la loi l'exige ou avec le consentement explicite de l'utilisateur.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Sécurité des données</h2>
                            <p className="landing_legal-container-content-content">Venco met en place des mesures de sécurité appropriées pour protéger les données contre l'accès non autorisé, la modification, la divulgation ou la destruction.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Droits des utilisateurs</h2>
                            <p className="landing_legal-container-content-content">Les utilisateurs peuvent demander la suppression de leurs données à tout moment en contactant Venco à freelance.guillaume@gmail.com. Les demandes seront traitées dans un délai raisonnable.</p>
                        </li>
                    </ol>

                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default LegalConfi;