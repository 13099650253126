import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import { Helmet } from 'react-helmet-async';

import Footer from '../../components/Footer/Footer';
import { set, useForm } from "react-hook-form";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import TextareaInput from '../../components/TextareaInput/TextareaInput';
import axios from 'axios';

import './style.scss';

const LegalCGV = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | Conditions Générales de Vente (CGV)",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }
    
    return (
        <div className='landing_legal'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_legal-container">

                <div className="landing_legal-container-content">

                    <h1 className="landing_legal-container-content-title">Conditions Générales de Vente (CGV)</h1>

                    <ol>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Objet</h2>
                            <p className="landing_legal-container-content-content">Les présentes Conditions Générales de Vente (CGV) régissent les relations contractuelles entre la société Venco (ci-après "Venco") et toute personne physique ou morale souhaitant souscrire aux services proposés par Venco (ci-après "le Client").</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Description des services</h2>
                            <p className="landing_legal-container-content-content">Venco offre l'accès à un dashboard permettant de créer des lieux et des récompenses pouvant être récupérées aléatoirement par les clients du lieu en échange d'un avis. Venco vend également des supports marketing pour mettre en avant les QR codes clients (Chevalet NFC et stickers QR code).</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Tarification</h2>
                            <p className="landing_legal-container-content-content">Différents plan d'abonnement : starter (9€/mois), advanced (19€/mois), all-in (39€/mois). Différents produits physiques : chevalet NFC (29€), stickers QRcode (9€).</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Modalités de paiement</h2>
                            <p className="landing_legal-container-content-content">Les paiements sont effectués mensuellement par carte bancaire. Le Client autorise Venco à prélever automatiquement les frais d'abonnement chaque mois.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Obligations des parties</h2>
                            <p className="landing_legal-container-content-content">Venco : Fournir l'accès au dashboard et assurer la disponibilité du service. Client : Payer les frais d'abonnement et/ou des produits physiques conformément aux tarifs en vigueur. Fournir des informations exactes et à jour.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Durée et résiliation</h2>
                            <p className="landing_legal-container-content-content">Les abonnements sont conclus pour une durée indéterminée et peuvent être annulés à tout moment par le Client sans préavis. La résiliation est effective immédiatement, et aucun remboursement ne sera effectué pour le mois en cours.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Limitation de responsabilité</h2>
                            <p className="landing_legal-container-content-content">Venco ne saurait être tenue responsable des interruptions de service ou des dommages indirects subis par le Client. En cas de force majeure, Venco peut suspendre ses obligations contractuelles.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Litiges</h2>
                            <p className="landing_legal-container-content-content">En cas de litige, une solution amiable sera recherchée avant toute action judiciaire. À défaut, le tribunal compétent sera celui du siège social de Venco.</p>
                        </li>
                    </ol>

                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default LegalCGV;