import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    menuOppened: JSON.parse(window?.localStorage.getItem("menuPosition")) ?? false,
    loading: false,
};

const globalslice = createSlice({
    name: "global",
    initialState,
    reducers: {
        toggleMenuOppened(state, action) {
            if (localStorage.getItem("menuPosition") !== null) {
                if (localStorage.getItem("menuPosition") == "true") {
                    localStorage.setItem("menuPosition", "false");
                    state.menuOppened = false;
                } else {
                    localStorage.setItem("menuPosition", "true");
                    state.menuOppened = true;
                }
            } else {
                localStorage.setItem("menuPosition", "false");
                state.menuOppened = false;
            }
        },
        toggleLoading(state, action) {
            state.loading = action.payload;
        },
    }
}) 

export default globalslice.reducer;

export function ToggleMenuOppened(global) {
    return (dispatch, getState) => {
        dispatch(globalslice.actions.toggleMenuOppened(global));
    }
}
export function ToggleLoading(loading) {
    return (dispatch, getState) => {
        dispatch(globalslice.actions.toggleLoading(loading));
    }
}