import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Navigate, Route, Routes, useLocation, Link, useParams } from 'react-router-dom';
import { UpdateIsReviewPosted, SetCurrentPlaceId } from '../../redux/clientSlice';
import { fetchPlaceBasics, fetchCurrentPlaceId } from '../../utils';
import axios from 'axios';
import ClientTopHeader from "../../components/ClientTopHeader/ClientTopHeader"


import './style.scss';

const ClientPostReview = () => {
    const { currentPlace, currentPlaceId } = useSelector((state) => state.client);
    const { isReviewPosted } = useSelector((state) => state.client);
    let { id } = useParams()
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const handleReviewClick = () => {
        dispatch(UpdateIsReviewPosted())
    };

    const fetchPlace = async () => {
        await fetchPlaceBasics(dispatch, `/places/get-place-basics/${id}`);
        setLoading(false)
    };
    const fetchPlaceId = async (extracted) => {
        await fetchCurrentPlaceId(dispatch, extracted);
    };
    useEffect(() => {
        if (Object.keys(currentPlace).length == 0) {
          setLoading(true)
          fetchPlace();
        }

        const regex = /\/place\/([^\/?]+)/;
        var placeUrl = currentPlace.placeUrl.match(regex)[1];

        axios.post(`${process.env.REACT_APP_API_URL}/places/get-place-id-test/`, {
            placeUrl: currentPlace.placeUrl
        })
        .then(async (res) => {
            dispatch(SetCurrentPlaceId(res?.data?.placeId))
        })
        .catch((err) => {
            console.log(err);
        })
    }, [])

    

    return (
        <div className={`post-review`}>
            <div className="landing-top" style={{backgroundImage: `url('${currentPlace?.image}'`}}>
                <ClientTopHeader />
            </div>
            <div className="post-review-bottom">
                <div className="post-review-bottom-content">
                    <h1>Déposez un avis et récupérer votre récompense !</h1>
                    <h4>N'oubliez pas de revenir ici récupérer votre récompense !</h4>
                </div>
                <div className="post-review-bottom-ctas">
                    {!isReviewPosted && <Link className='post-a-review-cta' onClick={() => {handleReviewClick()}} target='_blank' to={`https://search.google.com/local/writereview?placeid=${currentPlaceId}`}>J'écris un avis</Link>}
                    {isReviewPosted && <Link className='post-a-review-cta' to={location.pathname.replace("post-a-review", "login-google")}>C'est fait !</Link>}
                </div>
            </div>
        </div>
    )
}

export default ClientPostReview;