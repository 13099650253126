import './style.scss';

const CustomButton = ({title, style, iconRight, type, onClick, isDisabled = false }) => {



  const isCtaDisabled = isDisabled ? true : false;

  return (
    <button disabled={isCtaDisabled} className={"custom-button " + style} type={type || "button"} onClick={onClick}>
        {title}
        {iconRight && <span className='custom-button-right'>{iconRight}</span>}
    </button>
  )
}

export default CustomButton