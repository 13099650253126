import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { deletePlace } from '../../utils';

import moment from 'moment';

import './style.scss';

const PlacesTable = ({isCurrentLocation = false}) => {
    const { user, editUser } = useSelector((state) => state.user);
    const { places, editRewards } = useSelector((state) => state.place);
    const { rewards, editplaces } = useSelector((state) => state.reward);
    const dispatch = useDispatch();

    if (Object.keys(places).length) {
        var draftRewardsCount = 0
        var liveRewardsCount = 0
        var offRewardsCount = 0

        places.map((place) => {
            if (place.status == "draft") {
                draftRewardsCount++;
            } else if (place.status == "live") {
                liveRewardsCount++;
            } else {
                offRewardsCount++;
            }
        })
    }

    const handleDeletePlace = async (e) => {
        await deletePlace(user?.token, dispatch, `places/delete-place/${e._id}`);
    }

    const toggleMorePannel = (e) => {
        e.target.closest(".content-container-section-table-card").querySelector(".learnMore").classList.toggle("active")
    }

    return (
        <div className="content-container-section content-container-section_place">
            <div className="content-container-section-top">
                <h2 className="content-container-section-top-title">Vos lieux</h2>
                {!isCurrentLocation && <Link className='content-container-section-top-cta' to={"/lieux"}>Voir tout</Link>}
            </div>
            <div className="content-container-section-tables">
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Brouillon</p>
                        <span className='content-container-section-table-top-count'>{draftRewardsCount ? draftRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(places).length !== 0) && places.map((place, i) => {
                            if (place.status !== "draft") {
                                return;
                            }

                            var updatedAt = new Date(place.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{place.name}</h4>
                                    <p className="content-container-section-table-card-location"><span>Avis : </span>{Object.keys(place.newReviews).length}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/lieux/modifier/" + place._id} className="learnMore-item">Modifier</Link>
                                        <Link to={"/lieux/creer-qr/" + place._id} className="learnMore-item">Créer QR code</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des stickers avec QRcode</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des chevalets NFC + QRcode</Link>
                                        <span onClick={() => {handleDeletePlace(place)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom content-container-section-table-bottom_draft">
                        <Link to={"/lieux/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter un lieu</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>En ligne</p>
                        <span className='content-container-section-table-top-count'>{liveRewardsCount ? liveRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(places).length !== 0) && places.map((place, i) => {
                            if (place.status !== "live") {
                                return;
                            }

                            var updatedAt = new Date(place.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{place.name}</h4>
                                    <p className="content-container-section-table-card-location"><span>Avis : </span>{Object.keys(place.newReviews).length}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/lieux/modifier/" + place._id} className="learnMore-item">Modifier</Link>
                                        <Link to={"/lieux/creer-qr/" + place._id} className="learnMore-item">Créer QR code</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des stickers avec QRcode</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des chevalets NFC + QRcode</Link>
                                        <span onClick={() => {handleDeletePlace(place)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">
                        <Link to={"/lieux/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter un lieu</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Désactivé</p>
                        <span className='content-container-section-table-top-count'>{offRewardsCount ? offRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(places).length !== 0) && places.map((place, i) => {
                            if (place.status !== "off") {
                                return;
                            }

                            var updatedAt = new Date(place.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{place.name}</h4>
                                    <p className="content-container-section-table-card-location"><span>Avis : </span>{Object.keys(place.newReviews).length}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/lieux/modifier/" + place._id} onClick={() => {}} className="learnMore-item">Modifier</Link>
                                        <Link to={"/lieux/creer-qr/" + place._id} className="learnMore-item">Créer QR code</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des stickers avec QRcode</Link>
                                        <Link to={"/boutique/"} className="learnMore-item">Commander des chevalets NFC + QRcode</Link>
                                        <span onClick={() => {handleDeletePlace(place)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">
                        <Link to={"/lieux/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter un lieu</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlacesTable