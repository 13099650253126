import './App.css';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, Route, Routes, UseLocation, useLocation } from 'react-router-dom';
import { LandingSubscription, LegalCGV, ErrorPage, LegalCGU, LegalMentions, LegalConfi, LegalRefound, LandingFonctionnalites, LandingAbout, LandingContact, LandingPrice, Dashboard, Shop, Login, Profile, Register, ResetPassword, Landing, Lieux, Recompenses, LieuxForm, RecompensesForm, ClientLanding, ClientLandingSecond, ClientPostReview, ClientLoginGoogle, ClientReward, ClientEditor, Verifier, Scan, CreateQr, DataAnalytics, Support } from "./pages"
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo, fetchPlaces, fetchRewards } from './utils';
import moment from 'moment';
import Loading from './components/Loading/Loading';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Layout() {
  const {user} = useSelector(state => state.user);
  const location = useLocation();

  return user?.token ? (
    <Outlet/>
  ): (
    <Navigate to="/login" state={{from: location}} replace />
  )
}

function App() {
  const { theme } = useSelector((state) => state.theme)
  const { user, editUser } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.global);

  moment.locale('fr', {
      months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
      monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
      monthsParseExact: true,
      weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
      weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
      weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
      },
      calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
      },
      relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
      },
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === 'M';
      },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
      }
  });

  return (
    <HelmetProvider>
      <div className={"app " + theme + '-theme' }>
        {loading && typeof user?.token !== undefined && <Loading />}
        <Routes>
          <Route element={<Layout />}>
            <Route path='/dashboard/abonnement' element={<LandingSubscription />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/lieux' element={<Lieux />} />
            <Route path='/lieux/creer' element={<LieuxForm />} />
            <Route path='/lieux/modifier/:id' element={<LieuxForm />} />
            <Route path='/lieux/creer-qr/:id' element={<CreateQr />} />
            <Route path='/lieux/modifier/:id/personnaliser' element={<ClientEditor />} />
            <Route path='/recompenses/creer' element={<RecompensesForm />} />
            <Route path='/recompenses/modifier/:id' element={<RecompensesForm />} />
            <Route path='/recompenses' element={<Recompenses />} />
            <Route path='/analyse' element={<DataAnalytics />} />
            <Route path='/verifier/:id' element={<Verifier />} />
            <Route path='/scan/' element={<Scan />} />
            <Route path='/boutique/' element={<Shop />} />
            <Route path='/support/' element={<Support />} />
            <Route path='/profile/:id?' element={<Profile />} />
          </Route>

          {/* <Route path='/scan/' element={<Scan />} /> */}

          <Route path='/' element={<Landing />} />

          <Route path='/fonctionnalites' element={<LandingFonctionnalites />} />
          <Route path='/prix' element={<LandingPrice />} />
          <Route path='/a-propos' element={<LandingAbout />} />
          <Route path='/contact' element={<LandingContact />} />

          <Route path='/conditions-generales-de-vente' element={<LegalCGV />} />
          <Route path='/conditions-generales-utilisation' element={<LegalCGU />} />
          <Route path='/politique-de-confidentialite' element={<LegalConfi />} />
          <Route path='/politique-de-remboursement' element={<LegalRefound />} />
          <Route path='/mentions-legales' element={<LegalMentions />} />

          <Route path='*' element={<ErrorPage />} />

          <Route path='/client/:id' element={<ClientLanding />} />
          <Route path='/client/:id/has-review' element={<ClientLandingSecond />} />
          <Route path='/client/:id/post-a-review' element={<ClientPostReview />} />
          <Route path='/client/:id/login-google' element={<ClientLoginGoogle />} />
          <Route path='/client/:id/reward' element={<ClientReward />} />

          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
}

export default App;
