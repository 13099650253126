import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import LandingNav from '../../components/LandingNav/LandingNav';
import Footer from '../../components/Footer/Footer';
import HeroSection from '../../components/HeroSection/HeroSection';
import AnimationSection from '../../components/AnimationSection/AnimationSection';
import PricingSection from '../../components/PricingSection/PricingSection';
import FaqSection from '../../components/FaqSection/FaqSection';

import client from "./img/client.jpeg"
import placeBanner from "./img/placeBannerNew.jpeg"
import rewardBanner from "./img/rewardBanner.jpeg"
import newBanner from "./img/homeBennerNew.jpeg"
import thumbnail from "./img/thumb.png"
import thumnnail_new from "./img/thumnnail.png"
import video from "./video/tet.mp4"
import create_place from "./video/create_place.mp4"
import create_reward from "./video/create_reward.mp4";
import newaaaaa from "./video/new.mp4";


import './style.scss';

const Landing = () => {
    const { user, editUser } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    const handleThumbnailClick = () => {
        document.querySelector('.landing-container-video-content-video-mp4').play();
        document.querySelector('.landing-container-video-content-video-thumbnail').classList.add("hide");
    }

    var animationElements = [
        {
            video: create_place,
            title: "Créez vos lieux",
            desc: "Depuis votre dashboard créez vos lieux et adaptez leur interface utilisateur pour offrir à vos clients une expérience personnalisée."
        },
        {
            video: create_reward,
            title: "Créez vos récompenses",
            desc: "Depuis votre dashboard créez des récompenses et associez-les à vos lieux. Utilisez les récompenses pré-conçues par l'équipe Venco ou créez des récompenses 100% personnalisé pour coller à votre image de marque."
        },
        {
            video: newaaaaa,
            title: "Laissez vos clients faire la suite",
            desc: "Une fois vos lieux et récompenses créés, utilisez les supports Venco disponibles dans la boutique (chevalet NFC et stickers personnalisés) ou créez vos propres supports en utilisant le QRcode disponible dans votre dashboard pour permettre à vos clients de laisser un avis et reçevoir leur récompense."
        },
    ];

    var seo = {
        title: "Venco | La meilleure solution pour collecter davantage d'avis Google",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }


    return (
        <div className='landing dark'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing-container">

                <HeroSection img={newBanner} title="La meilleure solution pour collecter davantage d'avis Google" desc="Améliorez votre e-réputation en offrant des récompenses aux clients laissant un avis chez vous." />

                <section className="landing-container-video">
                    <div className="landing-container-video-content">
                        <div className="landing-container-video-content-top">
                            <div className="landing-container-video-content-top-left">
                                <p className="landing-container-video-content-top-left-subtitle">Recevez plus d'avis</p>
                                <h4 className="landing-container-video-content-top-left-title">Une offre adaptée à vos besoins</h4>
                            </div>
                            <div className="landing-container-video-content-top-right">
                                <h1 className="landing-container-video-content-top-right-desc">Personnalisez les récompenses que vous souhaitez offrir à vos clients en échange de leur avis Google. Attribuez plusieurs types de récompenses à un seul lieu et laissez le hasard faire les choses !</h1>
                                <Link to="/fonctionnalites" className="landing-container-video-content-top-right-cta">
                                    Découvrir
                                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg" class="fill-current"> <path d="M5 13.5H16.17L11.29 18.38C10.9 18.77 10.9 19.41 11.29 19.8C11.68 20.19 12.31 20.19 12.7 19.8L19.29 13.21C19.68 12.82 19.68 12.19 19.29 11.8L12.71 5.19997C12.32 4.80997 11.69 4.80997 11.3 5.19997C10.91 5.58997 10.91 6.21997 11.3 6.60997L16.17 11.5H5C4.45 11.5 4 11.95 4 12.5C4 13.05 4.45 13.5 5 13.5Z"></path> </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="landing-container-video-content-video">
                            <video muted loop controls={false} src={newaaaaa} className="landing-container-video-content-video-mp4" />
                            <img className='landing-container-video-content-video-thumbnail' onClick={() => {handleThumbnailClick()}} src={thumnnail_new} alt="support NFC venco" />
                        </div>
                        <div className="landing-container-video-content-bottom">
                            <div className="landing-container-video-content-bottom-card">
                                <h6 className="landing-container-video-content-bottom-card-title">Récompenses personnalisables</h6>
                                <p className="landing-container-video-content-bottom-card-desc">Créez tous les types de récompenses (pourcentage de réduction, produit/prestation offerte...)</p>
                            </div>
                            <div className="landing-container-video-content-bottom-card">
                                <h6 className="landing-container-video-content-bottom-card-title">Récupération des données client</h6>
                                <p className="landing-container-video-content-bottom-card-desc">Récupérez toutes les informations des clients ayant déposé un avis (nom, email, note et avis laissé...)</p>
                            </div>
                            <div className="landing-container-video-content-bottom-card">
                                <h6 className="landing-container-video-content-bottom-card-title">Une expérience utilisateur personnalisable</h6>
                                <p className="landing-container-video-content-bottom-card-desc">Personnalisez l'interface utilisateur pour offrir à vos clients une expérience unique dont ils se souviendront.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <AnimationSection elements={animationElements} />

                <PricingSection />

                <FaqSection />

                <Footer />

            </div>
        </div>
    )
}

export default Landing;