import React, { useState, componentDidMount, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";

import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';

import './style.scss';


const Profile = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const [istSubmitting, setistSubmitting] = useState(false);
    const {user} = useSelector(state => state.user);
    const [file, setFile] = useState(null);
    const [errMsg, setErrMsg] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })

    if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
        return <Navigate to={'/dashboard/abonnement'} replace />
    }

    const handlePlanChange = async (planType) => {
        var customerPlan = "";
        var userWantToCancel = false;

        // const starterPlanId = process.env.REACT_APP_STRIPE_STARTER_PLAN_ID_TEST;
        // const advancedPlanId = process.env.REACT_APP_STRIPE_ADVANCED_PLAN_ID_TEST;
        // const allinPlanId = process.env.REACT_APP_STRIPE_ALLIN_PLAN_ID_TEST;
        // const starterProductId = process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_TEST;
        // const advancedProductId = process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_TEST;
        // const allinProductId = process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_TEST;
        const starterPlanId = process.env.REACT_APP_STRIPE_STARTER_PLAN_ID_PROD;
        const advancedPlanId = process.env.REACT_APP_STRIPE_ADVANCED_PLAN_ID_PROD;
        const allinPlanId = process.env.REACT_APP_STRIPE_ALLIN_PLAN_ID_PROD;
        const starterProductId = process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_PROD;
        const advancedProductId = process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD;
        const allinProductId = process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_PROD;
        if (planType == "starter") {
            customerPlan = starterPlanId;
            if (user?.stripeProductId == starterProductId) {
                userWantToCancel = true
            }
        } else if (planType == "advanced") {
            customerPlan = advancedPlanId;
            if (user?.stripeProductId == advancedProductId) {
                userWantToCancel = true
            }
        } else if (planType == "allin") {
            customerPlan = allinPlanId;
            if (user?.stripeProductId == allinProductId) {
                userWantToCancel = true
            }
        } else {
            customerPlan = starterPlanId;
        }

        if (userWantToCancel) {
            var stripeSubscriptionId = user?.stripeSubscriptionId;
            const res = await apiRequest({
                url: "/stripe/cancel-subscription",
                token: user?.token,
                method: "POST",
            });
            const resBody = await res;
            console.log(resBody);

            // Update user
            const userUpdateRes = await apiRequest({
                url: "/users/update-stripe-user",
                token: user?.token,
                data: { stripeProductId: "", stripeProductName: "", stripeCustomerId: "", stripePaymentStatus: "", stripeProductName: "" },
                method: "PUT",
            });
            var test = await userUpdateRes;
            console.log(test);

            window.location.reload();
        } else {
            // Retrieve stripe subscription
            const resAa = await apiRequest({
                url: "/stripe/retrieve-subscription",
                method: "GET",
                token: user?.token
            });
            const body = await resAa;
            var subscriptionId = body?.subscriptionDetail?.items?.data[0]?.id;
            console.log(subscriptionId);
    
            // Update subscription
            const res = await apiRequest({
                url: "/stripe/update-subscription",
                token: user?.token,
                data: { newSubscriptionId: subscriptionId, priceId: customerPlan },
                method: "POST",
            });
            const resBody = await res;
            var stripeProductId = resBody?.subscriptionDetail?.plan?.product;
            console.log(stripeProductId);
    
            const productRes = await apiRequest({
                url: "/stripe/retrieve-product",
                token: user?.token,
                data: { stripeProductId: stripeProductId },
                method: "POST",
            });
            const productBody = await productRes;
            var stripeProductName = productBody.productDetail.name;
            console.log(productBody);
            console.log(stripeProductName);
    
            // Update user
            const userUpdateRes = await apiRequest({
                url: "/users/update-stripe-user",
                token: user?.token,
                data: { stripeProductId: stripeProductId, stripeProductName: stripeProductName },
                method: "PUT",
            });
            window.location.reload();
        }
    }

    const afterFileUpload = async (e) => {
        const uriaa = e && (await handleFileUpload(e));
        setFile(uriaa); 
    };

    const onSubmit = async (data) => {
        setistSubmitting(true);
        try {
            var uri = file;

            if (!file) {
                uri = user?.profileUrl
            }

            var newData = uri ? { ...data, profileUrl: uri } : data;

            console.log(newData);

            const res = await apiRequest({
                url: "/users/update-user",
                method: "PUT",
                data: newData,
                token: user?.token
            });

            if(!res?.success) {
                console.log("Fail update !");
                setErrMsg(res?.message);
            } else {
                setErrMsg("");
                console.log("Success update !");
                console.log(res);
                window.location.reload()
            }
            setistSubmitting(false);
        } catch (error) {
            console.log(error);
            setistSubmitting(false);
        }
    }

    console.log(user?.profileUrl);

    var seo = {
        title: "Venco | Mon compte",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`profile-page dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <div className="content-container">
                    <div className="content-container-top">
                        <div className="content-container-top-left">
                            <img src={user?.profileUrl ? user?.profileUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="profil" className="content-container-top-left-img" />
                            <div className="content-container-top-left-content">
                                <h2 className="content-container-top-left-content-top">{user?.firstName + " " + user?.lastName}</h2>
                                <h4 className="content-container-top-left-content-bottom">Modifiez vos informations personnelles</h4>
                            </div>
                        </div>
                    </div>

                    <div className="content-container-bottom">
                        <h3 className="content-container-bottom-title">Modifier le profil</h3>
                        <span className="content-container-bottom-title-divider"></span>

                        <form className='content-container-bottom-form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="content-container-bottom-form-content">
                                <TextInput
                                    label="Nom"
                                    name="lastName"
                                    placeholder="Votre nom"
                                    type="text"
                                    register={
                                    register("lastName", {
                                        required: "Votre nom est requis"
                                    })
                                    }
                                    style="personnal-input"
                                    error={errors.lastName ? errors.lastName.message : ''}
                                    value={user?.lastName ? user?.lastName : ""}
                                />
                                <TextInput
                                    label="Prénom"
                                    name="firstName"
                                    placeholder="Votre prénom"
                                    type="text"
                                    register={
                                    register("firstName", {
                                        required: "Votre prénom est requis"
                                    })
                                    }
                                    style="personnal-input"
                                    error={errors.firstName ? errors.firstName.message : ''}
                                    value={user?.firstName ? user?.firstName : ""}
                                />
                                <p className="text-input-label">Photo de profil</p>
                                <input type="file" accept='.jpg, .png, .jpeg' onChange={(e) => {afterFileUpload(e.target.files[0]); }} id="imgUpload" className="post-modal-form-image-uploader-input" />
                                <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title={"Modifier mes informations"} />
                            </div>
                        </form>



                        <h3 className="content-container-bottom-title">Abonnement</h3>
                        <span className="content-container-bottom-title-divider"></span>

                        <div className="subscription-container-cards">
                            <div className={`subscription-container-cards-card ${user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_TEST ? "active" : ""} `}>
                                <div className="subscription-container-cards-card-content">
                                    <h4 className="subscription-container-cards-card-title">
                                        <span>
                                        <svg height="24px" width="24px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <g>
                                                <path class="st0" d="M491.225,154.831c-19.769-45.85-52.417-84.667-93.449-112.004C356.779,15.491,307.264-0.342,254.354,0.014
                                                    c-35.262,0.221-68.962,7.602-99.522,20.77c-45.867,19.752-84.676,52.409-111.996,93.431
                                                    c-27.337,40.998-43.169,90.513-42.83,143.439c0.238,35.253,7.62,68.954,20.787,99.524c19.735,45.833,52.401,84.667,93.431,111.995
                                                    c40.998,27.337,90.513,43.17,143.422,42.812c35.262-0.22,68.962-7.61,99.524-20.761c45.867-19.76,84.675-52.426,111.995-93.44
                                                    c27.337-40.996,43.186-90.521,42.83-143.422C511.757,219.085,504.376,185.383,491.225,154.831z M145.515,94.362
                                                    c31.155-21.313,68.572-33.845,109.212-34.125c27.117-0.17,52.825,5.142,76.292,14.882c24.266,10.071,46.105,24.928,64.313,43.382
                                                    l-280.396,1.807C124.219,110.653,134.468,101.947,145.515,94.362z M75.127,180.98c1.239-2.995,2.58-5.905,3.954-8.815
                                                    l352.786-2.275c8.943,18.25,15.103,38.104,18.004,59.044l-388.098,2.503C63.979,213.764,68.527,196.855,75.127,180.98z
                                                    M366.468,417.63c-31.138,21.322-68.555,33.844-109.212,34.133c-27.116,0.162-52.807-5.15-76.292-14.882
                                                    c-24.249-10.079-46.088-24.927-64.296-43.39l280.379-1.799C387.782,401.34,377.533,410.053,366.468,417.63z M436.89,331.029
                                                    c-1.239,2.978-2.596,5.905-3.97,8.807l-352.77,2.274c-8.976-18.259-15.136-38.096-18.021-59.052l388.115-2.494
                                                    C448.021,298.245,443.474,315.146,436.89,331.029z"/>
                                            </g>
                                        </svg>
                                        </span>
                                        Starter plan
                                    </h4>
                                    <p className="subscription-container-cards-card-subtitle">Idéal pour les indépendants gérant un seul lieu</p>

                                    <p className="subscription-container-cards-card-price">9€<span className='subscription-container-cards-card-price-span'>/mois</span></p>

                                    <ul className="subscription-container-cards-card-list">
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            1 lieu
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            3 types de récompenses
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            Templates de QRcode personnalisables
                                        </li>
                                    </ul>
                                </div>

                                <button onClick={() => {handlePlanChange("starter")}} className={`subscription-container-cards-card-cta ${user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_TEST ? "disabled" : ""}`}>{user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_STARTER_PRODUCT_ID_TEST ? "Se désabonner" : "S'abonner"}</button>
                            </div>
                            <div className={`subscription-container-cards-card ${user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_TEST ? "active" : ""} `}>
                                <div className="subscription-container-cards-card-content">
                                    <h4 className="subscription-container-cards-card-title">
                                        <span>
                                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <g>
                                                    <rect y="239.908" class="st0" width="68.958" height="32.185"/>
                                                    <rect x="443.059" y="239.908" class="st0" width="68.941" height="32.185"/>
                                                    <polygon class="st0" points="26.248,370.057 42.341,397.943 102.053,363.464 85.961,335.586 	"/>
                                                    <polygon class="st0" points="485.752,141.934 469.658,114.057 409.947,148.536 426.039,176.413 	"/>
                                                    <polygon class="st0" points="114.065,469.658 141.943,485.752 176.422,426.039 148.544,409.947 	"/>
                                                    <polygon class="st0" points="397.952,42.341 370.057,26.248 335.596,85.961 363.473,102.053 	"/>
                                                    <rect x="239.916" y="443.051" class="st0" width="32.185" height="68.949"/>
                                                    <rect x="239.916" class="st0" width="32.185" height="68.949"/>
                                                    <polygon class="st0" points="335.596,426.039 370.057,485.752 397.952,469.658 363.473,409.947 	"/>
                                                    <polygon class="st0" points="176.422,85.961 141.943,26.248 114.065,42.341 148.544,102.053 	"/>
                                                    <polygon class="st0" points="409.947,363.464 469.658,397.943 485.752,370.057 426.039,335.586 	"/>
                                                    <polygon class="st0" points="102.053,148.536 42.341,114.057 26.248,141.934 85.961,176.413 	"/>
                                                    <path class="st0" d="M256.009,99.926c-86.207,0-156.074,69.877-156.074,156.074c0,86.197,69.868,156.074,156.074,156.074
                                                        c86.188,0,156.074-69.878,156.074-156.074C412.083,169.802,342.197,99.926,256.009,99.926z"/>
                                                </g>
                                            </svg>
                                        </span>
                                        Advanced plan
                                    </h4>
                                    <p className="subscription-container-cards-card-subtitle">Idéal pour les entreprises gérant entre 1 et 3 lieux</p>

                                    <p className="subscription-container-cards-card-price">19€<span className='subscription-container-cards-card-price-span'>/mois</span></p>

                                    <ul className="subscription-container-cards-card-list">
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            3 lieux
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            10 types de récompenses
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            Templates de QRcode personnalisables
                                        </li>
                                    </ul>
                                </div>

                                <button onClick={() => {handlePlanChange("advanced")}} className={`subscription-container-cards-card-cta ${user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_TEST ? "disabled" : ""}`}>{user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_TEST ? "Se désabonner" : "S'abonner"}</button>
                            </div>
                            <div className={`subscription-container-cards-card ${user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_TEST ? "active" : ""} `}>
                                <div className="subscription-container-cards-card-content">
                                    <h4 className="subscription-container-cards-card-title">
                                        <span>
                                        <svg version="1.1" id="_x32_" width="24px" height="24px" viewBox="0 0 512 512">
                                            <g>
                                                <path class="st0" d="M405.878,357.099c-3.641-41.297-29.703-99.953-92.734-102.781c-92.922,0.25-106.391,72.141-97.656,103.984
                                                    c7.328,26.75,26.781,48.078,54.422,45.656s48.469-26.844,45.656-54.438c-0.484-29.266,24.344-28.5,25.094,3.875
                                                    c1.016,43.094-28.094,80.922-71.031,84.703c-42.938,3.75-77.531-28.547-84.719-71.031c-4.344-25.781,0.594-75.172,44.438-113.766
                                                    c56.344-49.594,117.719,9.875,112.734-70.578c-5.031-81.188-25.438-82.906-78.063-114.531
                                                    c-34.797-20.922-40.75-54.109-38.797-67.109c0.078-0.625,0.078-1.563-1.156-0.781c-32.594,20.469-32.859,58-13.813,87.25
                                                    c9.141,14.016,28.031,21.234,29.969,43.422c2.641,30.094-14.672,39.281-28.938,51.703c-14.25,12.422-31.219,25.75-56.813,52.078
                                                    c-40.875,42.063-51.625,95.219-48.359,140.266c3.609,41.281,23.625,77.203,53.031,101.875
                                                    c29.453,24.688,68.313,38.141,109.594,34.516c41.297-3.625,77.203-23.641,101.906-53.063
                                                    C395.315,428.911,409.487,398.38,405.878,357.099z"/>
                                            </g>
                                        </svg>
                                        </span>
                                        All-in plan
                                    </h4>
                                    <p className="subscription-container-cards-card-subtitle">Idéal pour les entreprises gérant une multitude de lieux</p>

                                    <p className="subscription-container-cards-card-price">39€<span className='subscription-container-cards-card-price-span'>/mois</span></p>

                                    <ul className="subscription-container-cards-card-list">
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            10 lieux
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            30 types de récompenses
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            Templates de QRcode personnalisables
                                        </li>
                                        <li className="subscription-container-cards-card-list-item">
                                            <span className="subscription-container-cards-card-list-item-span">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_429_11249)">
                                                    <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_429_11249">
                                                    <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            Accès aux données clients (nom, email...)
                                        </li>
                                    </ul>
                                </div>

                                <button onClick={() => {handlePlanChange("allin")}} className={`subscription-container-cards-card-cta ${user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_PROD  || user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_TEST ? "disabled" : ""}`}>{user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_PROD || user?.stripeProductId === process.env.REACT_APP_STRIPE_ALLIN_PRODUCT_ID_TEST ? "Se désabonner" : "S'abonner"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile