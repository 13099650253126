import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    client: {
    },
    alreadyPostReview: undefined,
    currentPlace: {},
    currentPlaceId: "",
    isFetching: false,
    isReviewValid: false,
    isLoading: true,
    lastReviews: []
};

const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        setPlace(state, action) {
            state.currentPlace = action.payload;
        },
        login(state, action) {
            state.client = action.payload;
            localStorage.setItem("client", JSON.stringify(action.payload))
        },
        setCurrentPlaceId(state, action) {
            state.currentPlaceId = action.payload;
        },
        alreadyPostReview(state, action) {
            state.alreadyPostReview = true;
        },
        updateIsReviewPosted(state, action) {
            state.isReviewPosted = true;
        },
        isFetching(state, action) {
            state.isFetching = true;
        },
        setLastReviews(state, action) {
            state.lastReviews = action.payload;
            state.isFetching = false;
        },
        updateIsReviewValid(state, action) {
            state.isReviewValid = action.payload;
        },
        setLoading(state, action) {
            state.isLoading = false;
        },
    }
}) 

export default clientSlice.reducer;

export function SetPlace(place) {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.setPlace(place));
    }
}
export function SetCurrentPlaceId(placeId) {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.setCurrentPlaceId(placeId));
    }
}
export function AlreadyPostReview() {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.alreadyPostReview());
    }
}
export function UpdateIsReviewPosted() {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.updateIsReviewPosted());
    }
}
export function ClientLogin(user) {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.login(user));
    }
}
export function IsFetching(val) {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.isFetching(val));
    }
}
export function Test(val) {
    return (dispatch, getState) => {
        console.log(val);
        dispatch(clientSlice.actions.setLastReviews(val));
    }
}
export function UpdateIsReviewValid(val) {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.updateIsReviewValid(val));
    }
}
export function SetLoading() {
    return (dispatch, getState) => {
        dispatch(clientSlice.actions.setLoading());
    }
}