import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { usePDF } from 'react-to-pdf';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';


import { set, useForm } from "react-hook-form";

import './style.scss';


const CreateQr = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const location = useLocation();
    const [istSubmitting, setistSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })
    const [file, setFile] = useState(null);
    const {user} = useSelector(state => state.user);
    const [errMsg, setErrMsg] = useState("");
    const { places, editRewards } = useSelector((state) => state.place);
    const [currentLocation, setcurrentLocation] = useState({});
    const [currentQr, setcurrentQr] = useState({});
    const [title, settitle] = useState("Laissez un avis en échange d'une pizza !");
    const [subTitle, setsubTitle] = useState("Recevez gratuitement une pizza, un café ou un dessert en échange d'un avis Google !");

    let { id } = useParams()
    let {pathname} = useLocation();

    if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
        return <Navigate to={'/dashboard/abonnement'} replace />
    }

    if (typeof id !== "undefined" && Object.keys(currentLocation).length == 0) {
        (Object.keys(places).length !== 0) && places.map((place, i) => {
            if (place._id == id) {
                setcurrentLocation(place)
            }
        })
    }

    const generateQrCode = () => {
        var QrRequest = `${process.env.REACT_APP_APP_URL}/client/${currentLocation._id}`;
        var encodedQrRequest = encodeURIComponent(QrRequest);
        axios.post(`https://api.qrserver.com/v1/create-qr-code/?data=${encodedQrRequest}&size=200x200&format=png`)
            .then (res => {
                console.log(res);
                setcurrentQr(res.config.url);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleFileDownload = async () => {
        const image = await fetch(currentQr)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
      
        const link = document.createElement('a')
        link.href = imageURL
        link.download = 'qrcode'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }


    useEffect(() => {
        if (currentLocation?._id) {
            generateQrCode();
        }
    })

    const pdfExportComponent = React.useRef(null);
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
        }
    };

    const handleInputChange = (e, type) => {
        console.log(e.target.value);
        if (type == "title") {
            settitle(e.target.value)
        } else if (type ==  "subTitle") {
            setsubTitle(e.target.value)
        }
    }

    var seo = {
        title: "Venco | Gestion du QRcode",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard create-qr ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">QRcode du lieu</h2>
                <p className="content-subtitle">Retrouvez ici le QRcode permettant aux utilisateurs de laisser un avis. Vous pouvez utiliser ce QRcode sur des supports numériques ou l'imprimer. Vous souhaitez avoir des chevalets ou des stickers pour une meilleure expérience client ? Contactez-nous !</p>

                <img className='content-qr' src={currentQr ? currentQr : ""} alt="" />

                {Object.keys(currentQr).length !== 0 && <button className='content-qr-download' onClick={() => { handleFileDownload() }}>Télécharger QRcode</button>}

                <div className="content-bottom">
                    <h2 className="content-title">Télécharger un template</h2>
                    <p className="content-subtitle">Personnalisez le template avec vos informations et téléchargez-le directement pour une meilleure expérience client. Vous souhaitez un template différent ? Contactez-nous !</p>
                   
                   <div className="content-bottom-container">
                        <div className="left-form">
                            <div className="inputContainer">
                                <p className="text-input-label">Titre</p>
                                <input onChange={(e) => {handleInputChange(e, "title")}} defaultValue={"Laissez un avis en échange d'une pizza"} className={'text-input-input personnal-input'} type={"text"} name={"placeName"} placeholder={"Le nom de l'établissement"}></input>
                            </div>
                            <div className="inputContainer">
                                <p className="text-input-label">Sous-titre</p>
                                <input onChange={(e) => {handleInputChange(e, "subTitle")}} defaultValue={"Recevez gratuitement une pizza, un café ou un dessert en échange d'un avis Google !"} className={'text-input-input personnal-input'} type={"text"} name={"placeName"} placeholder={"Le nom de l'établissement"}></input>
                            </div>
                            <button className='content-qr-template-download' onClick={() => { exportPDFWithComponent() }}>Télécharger le template</button>
                        </div>

                        <div className="template-parent">
                            <PDFExport ref={pdfExportComponent} paperSize="auto">
                                <div className='test'>
                                    <h2 className='title'>{title}</h2>
                                    <p className='subtitle'>{subTitle}</p>
                                    <h1 className='scan-title'>Scannez ce QRcode</h1>
                                    <img className='qr' src={currentQr ? currentQr : ""} alt="" />
                                </div>
                            </PDFExport>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default CreateQr