import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate } from "react-router-dom";

import moment from 'moment';

import { useForm } from "react-hook-form";
import TopBar from '../../components/TopBar/TopBar';
import { getUserInfo, fetchPlaces, fetchRewards } from '../../utils';
import { UserLogin } from '../../redux/userSlice'
import Loading from '../../components/Loading/Loading';
import LeftNav from "../../components/LeftNav/LeftNav";
import TopData from "../../components/TopData/TopData";
import RewardsTable from "../../components/RewardsTable/RewardsTable";

import './style.scss';


const Recompenses = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const {user} = useSelector(state => state.user);

    if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
        return <Navigate to={'/dashboard/abonnement'} replace />
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>
            <LeftNav />
            <div className="content">

                <div className="content-container">

                    <TopData />
                    <RewardsTable isCurrentLocation={true} />

                </div>

            </div>
        </div>
    )
}

export default Recompenses