import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    rewards: {},
};

const rewardSlice = createSlice({
    name: "rewards",
    initialState,
    reducers: {
        getRewards(state, action) {
            state.rewards = action.payload;
        },
    }
}) 

export default rewardSlice.reducer;

export function SetRewards(rewards) {
    return (dispatch, getState) => {
        dispatch(rewardSlice.actions.getRewards(rewards));
    }
}