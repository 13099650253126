import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import { set, useForm } from "react-hook-form";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import TextareaInput from '../../components/TextareaInput/TextareaInput';
import axios from 'axios';

import './style.scss';

const LegalMentions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | Mentions légales",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }
    
    return (
        <div className='landing_legal'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_legal-container">

                <div className="landing_legal-container-content">

                    <h1 className="landing_legal-container-content-title">Mentions légales</h1>

                    <ol>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Identité de l'entreprise</h2>
                            <p className="landing_legal-container-content-content">Nom : Venco ; forme juridique : auto entreprise ; adresse : 8 chemin de lasseguette ; directeur : Guillaume PIRARD.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Hébergeur du site</h2>
                            <p className="landing_legal-container-content-content">Nom : Hostinger ; adresse : 61 Lordou Vironos Street, 6023 Larnaca, Chypre ; contact : https://www.hostinger.com/contact.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Contact</h2>
                            <p className="landing_legal-container-content-content">Vous pouvez joindre le service client Venco via le lien contact du menu ou par email : freelance.guillaume@yopmail.com ou par téléphone : 0609465781.</p>
                        </li>
                    </ol>

                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default LegalMentions;