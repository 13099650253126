import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';


import { set, useForm } from "react-hook-form";

import './style.scss';


const RecompensesForm = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);

    const [istSubmitting, setistSubmitting] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "onChange" })
    const [file, setFile] = useState(null);
    const {user} = useSelector(state => state.user);
    const [errMsg, setErrMsg] = useState("");
    const { places, editPlaces } = useSelector((state) => state.place);
    const { rewards, editRewards } = useSelector((state) => state.reward);
    const [currentLocation, setcurrentLocation] = useState({});
    const [currentReward, setcurrentReward] = useState({});
    const [maxRewardsReached, setmaxRewardsReached] = useState(false);
    let { id } = useParams()
    let {pathname} = useLocation();

    if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
        return <Navigate to={'/dashboard/abonnement'} replace />
    }

    if (typeof id !== "undefined" && Object.keys(currentReward).length == 0) {
        (Object.keys(rewards).length !== 0) && rewards.map((reward, i) => {
            if (reward._id == id) {
                setcurrentReward(reward)
            }
        })
    }

    const onSubmit = async (data) => {
        setistSubmitting(true);
    
        try {
            if (Object.keys(currentReward).length == 0) {
                // const uri = file && (await handleFileUpload(file));
                // const newData = uri ? { ...data, image: uri } : data;
    
                const res = await apiRequest({
                    url: "/rewards/create-reward",
                    method: "POST",
                    data: data,
                    token: user?.token
                });
    
                if(!res?.sucess) {
                    console.log("Fail creation !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success creation !");
                    window.location.replace("/dashboard");
                }
            } else {
                // var uri = file && (await handleFileUpload(file));
                // !file ? uri = currentReward?.image : uri = undefined;

                // var newData = uri ? { ...data, image: uri }: data;
                var newData = { ...data, _id: currentReward._id }

                const res = await apiRequest({
                    url: "/rewards/update-reward",
                    method: "POST",
                    data: newData,
                    token: user?.token
                });
    
                if(!res?.success) {
                    console.log("Fail update !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success update !");
                    window.location.replace("/dashboard");
                }
            }
          setistSubmitting(false);
        } catch (error) {
          console.log(error);
          setistSubmitting(false);
        }
    
    }

    const afterFileUpload = async (e) => {
        const uriaa = e && (await handleFileUpload(e));
        setFile(uriaa); 
    };

    console.log(places);

    var maxRewards = 3;
    if (user?.stripeProductId === "prod_PrwYviwlh2mGXf") {
        // Advanced plan
        maxRewards = 10;
    } else if(user?.stripeProductId === "prod_PrwZQg2iXCJo9h") {
        // All in plan
        maxRewards = 30;
    } else if (user?.stripeProductId === "prod_PrwSa5XbDQVAL5") {
        // Starter plan
        maxRewards = 3;
    }
    if (maxRewards == Object.keys(rewards).length && !maxRewardsReached) {
        setmaxRewardsReached(true)
    }


    const presets = [
        {
            name: "Café gratuit",
            reward: "Café gratuit",
            rarity: "common",
            status: "live"
        },
        {
            name: "Dessert gratuit",
            reward: "Dessert gratuit",
            rarity: "rare",
            status: "live"
        },
        {
            name: "-10% sur l'addition",
            reward: "-10% sur l'addition",
            rarity: "rare",
            status: "live"
        },
        {
            name: "Aucune récompense",
            reward: "rien",
            rarity: "common",
            status: "live"
        },
    ]

    const handlePressetClick = (preset) => {
        setValue('name', preset?.name);
        setValue('reward', preset?.reward);
        setValue('rarity', preset?.rarity);
        setValue('status', preset?.status);
    };

    var seo = {
        title: "Venco | Modification de la récompense",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }


    return (
        <div className={`dashboard create-reward ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">{pathname.includes("creer") ? "Créer une nouvelle " : "Modifier la "}récompense</h2>
                <p className="content-subtitle">Modifiez/créez vos récompenses ici. Donnez un nom à votre récompense, un statut, une rareté et assignez votre récompense à un lieu (pensez à créer un lieu avant de créer vos récompenses). N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>

                <div className="content-preset">
                    <h4 className="content-preset-title">Préréglages :</h4>
                    {presets?.map((preset) => {
                        return (
                            <span className="content-preset-pills" onClick={() => {handlePressetClick(preset)}}>{preset?.name}</span>
                        )
                    })}
                </div>

                <form className='content-form-container' onSubmit={handleSubmit(onSubmit)}>
                    <div className="content-form-container-left">
                        <TextInput
                            label="Nom"
                            name="name"
                            placeholder="Le nom de votre récompense"
                            type="text"
                            register={
                            register("name", {
                                required: "Nom de récompense requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.name ? errors.name.message : ''}
                            value={currentReward?.name ? currentReward?.name : ""}
                        />
                        <TextInput
                            label="Récompense"
                            name="reward"
                            placeholder="-30% sur votre prochaine commande !"
                            type="text"
                            register={
                            register("reward", {
                                required: "Récompense requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.reward ? errors.reward.message : ''}
                            value={currentReward?.reward ? currentReward?.reward : ""}
                        />
                        <SelectInput
                            label="Lieu associé à la récompense"
                            name="placeId"
                            type="select"
                            register={
                            register("placeId", {
                                required: "placeId requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.placeId ? errors.placeId.message : ''}
                            values={places}
                            value={currentReward?.placeId ? currentReward?.placeId : ""}
                        />
                        <CustomButton isDisabled={istSubmitting || maxRewardsReached ? true : false} type="submit" style="submit-cta" title={pathname.includes("creer") ? "Créer une récompense" : "Modifier la récompense"} />
                        {maxRewardsReached && <p className='content-form-container-left-erreur'>Vous avez atteint votre limite de lieux autorisé, vous pouvez <Link to={`/profile/${user?._id}`} className='content-form-container-left-erreur-link'>modifier votre abonnement</Link> pour débloquer plus de lieux.</p>}
                    </div>
                    <div className="content-form-container-right">
                        <SelectInput
                            label="Statut"
                            name="status"
                            type="select"
                            register={
                            register("status", {
                                required: "Statut requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.status ? errors.status.message : ''}
                            values={[
                                {
                                    "id": "draft",
                                    "name": "Brouillon"
                                },
                                {
                                    "id": "live",
                                    "name": "En ligne"
                                },
                                {
                                    "id": "off",
                                    "name": "Hors ligne"
                                }
                            ]}
                            value={currentReward?.status ? currentReward?.status : ""}
                        />
                        <SelectInput
                            label="Rareté"
                            name="rarity"
                            type="select"
                            register={
                            register("rarity", {
                                required: "Rareté requis"
                            })
                            }
                            values={[
                                {
                                    "id": "common",
                                    "name": "Commun (80%)"
                                },
                                {
                                    "id": "rare",
                                    "name": "Rare (20%)"
                                }
                            ]}
                            style="personnal-input"
                            error={errors.rarity ? errors.rarity.message : ''}
                            value={currentReward?.rarity ? currentReward?.rarity : ""}
                        />
                    </div>

                </form>
            </div>
        </div>
    )
}

export default RecompensesForm