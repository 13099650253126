import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import './style.scss';

import logo from "./svg/logo.png"

const LandingNav = () => {
    const { user, editUser } = useSelector((state) => state.user);

    const handleCloseNav = () => {
        document.querySelector('.landing-nav-panel').classList.remove('oppened')
    }
    const handleOpenNav = () => {
        document.querySelector('.landing-nav-panel').classList.add('oppened')
    }

    return (
        <div className="landing-nav">
            <Link className='landing-nav-left' to={"/"}>
                <img src={logo} alt="" />
            </Link>
            <ul className="landing-nav-items">
                <NavLink to={"/"}>
                    <li className="landing-nav-item">Accueil</li>
                </NavLink>
                <NavLink to={"/fonctionnalites"}>
                    <li className="landing-nav-item">Fonctionnalités</li>
                </NavLink>
                <NavLink to={"/prix"}>
                    <li className="landing-nav-item">Prix</li>
                </NavLink>
                {/* <NavLink to={"/a-propos"}>
                    <li className="landing-nav-item">A propos</li>
                </NavLink> */}
                <NavLink to={"/contact"}>
                    <li className="landing-nav-item">Contact</li>
                </NavLink>
            </ul>
            <div className="landing-nav-right">
                <Link to={user?.token ? "/dashboard" : "/login"} className="landing-nav-right-login">{user?.token ? "Dashboard" : "Se connecter"}</Link>
                <Link to="/register" className="landing-nav-right-register">Créer un compte</Link>
            </div>
            <div onClick={() => {handleOpenNav()}} className="landing-nav-burger">
                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
                    <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
                    <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </div>

            <div className="landing-nav-panel">
                <ul className="landing-nav-items">
                    <div className="top-container">
                        <NavLink to={"/"}>
                            <li className="landing-nav-item">Accueil</li>
                        </NavLink>
                        <NavLink to={"/fonctionnalites"}>
                            <li className="landing-nav-item">Fonctionnalités</li>
                        </NavLink>
                        <NavLink to={"/prix"}>
                            <li className="landing-nav-item">Prix</li>
                        </NavLink>
                        {/* <NavLink to={"/a-propos"}>
                            <li className="landing-nav-item">A propos</li>
                        </NavLink> */}
                        <NavLink to={"/contact"}>
                            <li className="landing-nav-item">Contact</li>
                        </NavLink>
                    </div>
                    <div className="ctas-container">
                        <Link to={user?.token ? "/dashboard" : "/login"} className="landing-nav-right-login">{user?.token ? "Dashboard" : "Se connecter"}</Link>
                        <Link to="/register" className="landing-nav-right-register">Créer un compte</Link>
                    </div>
                </ul>

                <div className="landing-nav-panel-close" onClick={() => {handleCloseNav()}}>X</div>
            </div>
        </div>
    )
}

export default LandingNav