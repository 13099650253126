import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';

import LeftNav from "../../components/LeftNav/LeftNav";


import './style.scss';


const Support = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    var seo = {
        title: "Venco | Contact",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"} support`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>
            
            <LeftNav />
            <div className="content">
                <h2 className="content-title">Contact</h2>
                <p className="content-subtitle">Si vous rencontrez le moindre problème, envoyez nous un mail à <a href= "mailto: contact@venco.fr">"contact@venco.fr"</a>. Nous répondons en général en moins de 24 heures.</p>
                
                <a href="mailto: contact@venco.fr" className='custom-button submit-cta'>Envoyer un mail</a>
            </div>
        </div>
    )
}

export default Support