import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import PricingSection from '../../components/PricingSection/PricingSection';
import FaqSection from '../../components/FaqSection/FaqSection';
import Footer from '../../components/Footer/Footer';


import './style.scss';

const LandingPrice = () => {
    const { user, editUser } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | La meilleure solution pour collecter davantage d'avis Google",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className='landing_fonctionnalites dark'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_fonctionnalites-container">

                <PricingSection />
                <FaqSection />
                <Footer />

            </div>
        </div>
    )
}

export default LandingPrice;