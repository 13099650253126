import './style.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <img src="https://media3.giphy.com/media/McUBKCpESJD0F7eqzT/giphy.gif?cid=ecf05e47zv5vgmwi36b4yg72rc4zbycpjgte1ntrv93z2gdn&ep=v1_stickers_search&rid=giphy.gif&ct=s" alt="" />
    </div>
  )
}

export default Loading