import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { set, useForm } from "react-hook-form";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import TextareaInput from '../../components/TextareaInput/TextareaInput';
import axios from 'axios';

import './style.scss';

const LegalCGU = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | Conditions Générales d'Utilisation (CGU)",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }
    
    return (
        <div className='landing_legal'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_legal-container">

                <div className="landing_legal-container-content">

                    <h1 className="landing_legal-container-content-title">Conditions Générales d'Utilisation (CGU)</h1>

                    <ol>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Accès au service</h2>
                            <p className="landing_legal-container-content-content">Le service est accessible via le lien venco.fr. L'accès au service nécessite une connexion Internet. Venco n'est pas responsable des problèmes de connexion dus au fournisseur d'accès à Internet du Client.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Compte utilisateur</h2>
                            <p className="landing_legal-container-content-content">Le Client doit créer un compte pour utiliser les services de Venco. Le Client s'engage à fournir des informations exactes et à jour. Le Client est responsable de la confidentialité de ses identifiants de connexion.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Utilisation acceptable</h2>
                            <p className="landing_legal-container-content-content">Le Client s'engage à utiliser le service de manière légale et respectueuse. Toute utilisation abusive, frauduleuse, ou contraire aux CGU peut entraîner la suspension ou la résiliation du compte du Client.</p>
                        </li>
                        <li>
                            <h2 className="landing_legal-container-content-subtitle">Modification des conditions</h2>
                            <p className="landing_legal-container-content-content">Venco se réserve le droit de modifier les CGU à tout moment. Les utilisateurs seront informés des modifications par e-mail ou via une notification sur le site. En continuant d'utiliser le service après les modifications, le Client accepte les nouvelles conditions.</p>
                        </li>
                    </ol>

                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default LegalCGU;