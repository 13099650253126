import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlacesReviews, fetchRewards } from '../../utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Helmet } from 'react-helmet-async';
import { UserLogin } from '../../redux/userSlice';
import axios from 'axios';

import LeftNav from "../../components/LeftNav/LeftNav";


import './style.scss';


const DataAnalytics = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { places, editRewards } = useSelector((state) => state.place);
    const { reviews } = useSelector((state) => state.review);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const fetchAllReviews = async (test) => {
        await fetchPlacesReviews(user?.token, dispatch, "reviews/get-place-reviews", {"placeId": test});
    };

    useEffect(() => {
        if (typeof user?.token !== undefined && Object.keys(places).length !== 0 && Object.keys(reviews).length == 0) {
            places?.map((place) => {
                fetchAllReviews(place._id)
            })
        }
    }, [places])
    
    if (user?.stripeProductId !== "prod_PrwZQg2iXCJo9h") {
        return (<Navigate to={'/dashboard'} replace />);
    };

    var seo = {
        title: "Venco | Analyse des données client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <div className="content-container data-analytics">
                    <h1 className='data-analytics-title'>Analyse des données client</h1>
                    <p className="data-analytics-subtitle">Retrouvez ici toutes les informations sur les clients ayant laissé un avis chez vous. Retrouvez le nom, l'email, la note et l'avis que vous ont laissé vos clients.</p>
                    
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename="export-avis-client"
                        sheet="export-avis-client"
                        buttonText="Exporter en XLS"
                    />

                    <div className="tableContainer">
                        <table id="table-to-xls">
                            <thead>
                                <tr>
                                    <th scope="col">Nom du client</th>
                                    <th scope="col">Lieu</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Avis</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviews.map((review, i) => {
                                    if (!review?.clientInfos) {
                                        return;
                                    }
                                    var placeReviewed = places.find(place => place?._id.toString() == review?.placeReviewed.toString());
                                    
                                    return (
                                        <tr key={i}>
                                            <td>{review?.clientInfos?.reviewUser?.name}</td>
                                            <td>{placeReviewed?.name}</td>
                                            <td>{review?.reviewerEmail}</td>
                                            <td>{review?.clientInfos?.reviewRating}/5</td>
                                            <td>{review?.clientInfos?.reviewSnippet}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataAnalytics