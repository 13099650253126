import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Faq from 'react-faq-component';
import './style.scss';

const FaqSection = () => {
    const { user, editUser } = useSelector((state) => state.user);
    
    const faqData = {
        title: "Foire Aux Questions",
        rows: [
          {
            title: "Comment cela fonctionne ?",
            content: "Inscrivez-vous, créez un lieu et associez lui des récompenses. Une fois votre lieu et vos récompenses créés, utilisez les supports marketing Venco (chevalet NFC / stickers QRcode) ou téléchargez un template de QR code à imprimer. Montrez les supports visuels à vos clients et c'est tout ! Laissez vos clients scanner le QRcode et suivre les différentes étapes du portail client Venco, ils recevront automatiquement leur récompense par mail si leur avis est valide. Le client viendra ensuite vous voir avec l'email qu'il aura reçu, vous aurez juste à le scanner en utilisant votre téléphone pour voir leur récompense."
          },
          {
            title: "Pourquoi utiliser Venco ?",
            content: "Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement."
          },
          {
            title: "Est-ce vraiment rentable si j'offre une récompense à chaque avis ?",
            content: "Chaque avis Google vous permet d'améliorer votre e-réputation tout en améliorant votre référencement, vous permettant d'être plus visible lors des recherches Google. De plus, c'est vous qui choisissez le type de récompense à offrir aux clients, ce qui vous permet de contrôler votre budget."
          },
          {
            title: "Est-ce long à mettre en place ?",
            content: "Comptez 1 heure pour paramétrer votre restaurant ainsi que vos récompenses dans votre dashboard, c'est tout. Vous n'avez pas le temps de configurer vos lieux et récompenses ? Pas de soucis, vous aurez la possibilité de déléguer cette tâche à un expert Venco lorsque vous choisirez votre type d'abonnement."
          },
          {
            title: "Puis-je annuler à tout moment ?",
            content: "En souscrivant à Venco, vous ne vous engagez à rien ! Désabonnez-vous en moins de 2 clics quand vous le souhaitez depuis votre espace client Venco."
          }
        ]
    }

    return (
        <section id='landing-container-faq' className="landing-container-faq">
            <div className="landing-container-faq-content">

                <Faq data={faqData}/>

            </div>
        </section>
    )
}

export default FaqSection