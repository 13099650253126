import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.scss';

const AnimationSection = ({elements}) => {
    const { user, editUser } = useSelector((state) => state.user);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        var left = document.querySelector('.landing-container-animation-content-container-left');
        var height = window.innerHeight / 4;
        var allVideos = document.querySelectorAll('.landing-container-animation-content-container-right-video');

        gsap.to(left, {
            ease: "none",
            scrollTrigger: {
                trigger: ".landing-container-animation-content-container",
                scrub: 0,
                pin: '.landing-container-animation-content-container-right',
                start: `top ${height}`,
                end: `bottom ${height * 3}`
            },
            onUpdate: () => {
                if (window.innerWidth >= 1024) {
                    document.querySelectorAll('.landing-container-animation-content-container-left-item').forEach((e, i) => {
                        if (e.getBoundingClientRect().top > 0 && e.getBoundingClientRect().top < (window.innerHeight / 2)) {
                            e.classList.add("active")
                            if (!allVideos[i].classList.contains("active")) {
                                allVideos[i].currentTime = 0;
                            }
                            allVideos[i].classList.add("active");
                        } else {
                            e.classList.remove("active")
                            allVideos[i].classList.remove("active");
                        }
                    })
                }
            }
        })
    })
    

    return (
        <section className="landing-container-animation">
            <div className="landing-container-animation-content">

                <div className="landing-container-animation-content-container">
                <div className="landing-container-animation-content-container-left">
                    {elements.map((element, i) => {
                        return(
                            <div className="landing-container-animation-content-container-left-item">
                                <h4 className="landing-container-animation-content-container-left-item-title">{element.title}</h4>
                                <p className="landing-container-animation-content-container-left-item-desc">{element.desc}</p>
                                <video playsInline loop autoPlay muted src={element.video} className="landing-container-animation-content-container-left-item-video"></video>
                            </div>
                        )
                    })}
                </div>
                <div className="landing-container-animation-content-container-right">
                    {elements.map((element, i) => {
                        return (
                            <video playsInline loop autoPlay muted src={element.video} className={`landing-container-animation-content-container-right-video ${i == 0 ? "active" : ""}`}></video>
                        )
                    })}
                </div>
                </div>

            </div>
        </section>
    )
}

export default AnimationSection