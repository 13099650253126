import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Navigate, redirect, useNavigate, Route, Routes, useLocation, Link, useHistory } from 'react-router-dom';
import Draggable from 'react-draggable';
import { AlreadyPostReview } from '../../redux/clientSlice';

import ClientTopHeader from "../../components/ClientTopHeader/ClientTopHeader"


import './style.scss';

const ClientLandingSecond = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { currentPlace, editCurrentPlace } = useSelector((state) => state.client);

    const handleAlreadyPostedReview = () => {
        dispatch(AlreadyPostReview())
    }

    return (
        <div className={`landing_second`}>
            <div className="landing-top" style={{backgroundImage: `url('${currentPlace?.image}'`}}>
                <ClientTopHeader />
            </div>
            <div className="landing_second-bottom">
                <div className="landing_second-bottom-content">
                    <h1>Avez-vous déjà déposé un avis ?</h1>
                    <h4>Nous allons vérifier votre avis afin de vous donner votre récompense</h4>
                </div>
                <div className="landing_second-bottom-ctas">
                    <Link className="yes" onClick={() => {handleAlreadyPostedReview()}} to={location.pathname.replace("has-review", "login-google")}>Oui !</Link>
                    <Link className="no" to={location.pathname.replace("has-review", "post-a-review")}>Non..</Link>
                </div>
            </div>
        </div> 
    )
}

export default ClientLandingSecond;