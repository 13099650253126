import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { TbSocial } from "react-icons/tb";
import TextInput from '../../components/TextInput/TextInput';
import Loading from '../../components/Loading/Loading';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../utils';
import { UserLogin } from '../../redux/userSlice';
import { Helmet } from 'react-helmet-async';

import './style.scss';

const Login = () => {

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })

  const [errMsg, setErrMsg] = useState("");
  const [istSubmitting, setistSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setistSubmitting(true);

    console.log(data);

    try {
      const res = await apiRequest({
        url: "/auth/login",
        method: "POST",
        data,
      })

      if (res?.status === "failed") {
        setErrMsg(res);
      } else {
        setErrMsg("");

        const newData = {
          token: res?.token,
          ...res?.user
        }
        dispatch(UserLogin(newData));
        window.location.replace("/dashboard")
      }
      setistSubmitting(false);
    } catch (error) {
      console.log(error);
      setistSubmitting(false);
    }

  }

  var seo = {
    title: "Venco | Connexion",
    description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
    image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
    creator: "@briock_",
    url: "http://www.venco.fr/",
    site_name: "Venco"
}

  return (
    <div className='login'>

        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />

          {/* Twitter meta */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={seo.creator} />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />

          {/* Facebook meta */}
          <meta property="og:title" content={seo.title}/>
          <meta property="og:url" content="http://www.venco.fr/"/>
          <meta property="og:image" content={seo.image}/>
          <meta property="og:site_name" content={seo.site_name}/>
          <meta property="og:description" content={seo.description}/>
      </Helmet>

      <div className="login-content">
        <div className="login-content-logo" onClick={() => {window.location.replace("/")}}>
          <span>Venco</span>
        </div>

        <h1 className="login-content-title">Vous revoilà !</h1>
        <p className="login-content-text">Entrez vos informations</p>

        <form className='login-content-form' onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="email"
            placeholder="email@example.com"
            type="email"
            register={
              register("email", {
                required: "Adresse mail requise"
              })
            }
            style="email-input"
            error={errors.email ? errors.email.message : ''}
          />
          <TextInput
            name="password"
            placeholder="Mot de passe"
            type="password"
            register={
              register("password", {
                required: "Mot de passe requise"
              })
            }
            style="password-input"
            error={errors.password ? errors.password.message : ''}
          />

          <div className="login-content-bottom">
            <Link to="/reset-password">Mot de passe oublié</Link>
          </div>


          {errMsg?.message && (
            <span className={`login-content-error ${errMsg?.status == "failed" ? "failed" : "sucess"}`}>{errMsg?.message}</span>
          )}

          <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title="Me connecter" />

        </form>

        <p className="login-content-text no-account">Vous n'avez pas de compte ? <Link to="/register" className='login-content-left-link'>Créer un compte</Link></p>

      </div>
    </div>
  )
}

export default Login