import React, { useState } from 'react';

import './style.scss';

const TextInput = React.forwardRef(
    (
        { type, placeholder, style, label, register, name, error, value = "", help = {}, helpCta = {}, onChange = false}, 
        ref
    ) => {

        const handleHelp = (e) => {
            document.querySelector('.text-input-help').classList.add("visible");
        }

        const handleCloseHelp = (e) => {
            document.querySelector('.text-input-help').classList.remove("visible");
        }
        
        return (
            <div className='text-input'>
                {label && <p className="text-input-label">{label} {Object.keys(help).length !== 0 ? <span className='text-input-label-help' onClick={(e) => {handleHelp(e)}}>?</span> : ""}{Object.keys(helpCta).length !== 0 ? <span className={'text-input-label-help' + " " + helpCta.className}>?</span> : ""}</p>}
                <input defaultValue={value} className={'text-input-input ' + style} type={type} name={name} placeholder={placeholder} ref={ref} {...register} aria-invalid={error ? "true" : "false"} ></input>
                <span className={"text-inut-error " + error !== '' ? 'isVisible' : ''}>{error}</span>

                {Object.keys(help).length !== 0 && (
                    <div className='text-input-help'>
                        <img src={help.imgUrl} alt="help" />
                        <p className='help-text'>{help.textContent}</p>
                        <div className="close" onClick={() => {handleCloseHelp()}}>x</div>
                    </div>
                ) }
            </div>
        )
    }
)

export default TextInput;