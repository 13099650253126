import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import moment from 'moment';

import { useForm } from "react-hook-form";
import TopBar from '../../components/TopBar/TopBar';
import { fetchReward, fetchReview, updateReview } from '../../utils';
import { UserLogin } from '../../redux/userSlice'
import Loading from '../../components/Loading/Loading';
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import LeftNav from "../../components/LeftNav/LeftNav";
import TopData from "../../components/TopData/TopData";
import RewardsTable from "../../components/RewardsTable/RewardsTable";
import PlacesTable from "../../components/PlacesTable/PlacesTable";

import './style.scss';


const Verifier = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { reviewToVerify } = useSelector((state) => state.review);
    const { rewards } = useSelector((state) => state.reward);
    const { user } = useSelector(state => state.user);
    const [currentReview, setcurrentReview] = useState({});
    const [expirationDate, setexpirationDate] = useState(null);
    const dispatch = useDispatch();
    var {id}  = useParams();

    if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
        return <Navigate to={'/dashboard/abonnement'} replace />
    }

    const handleFetchReview = async () => {
        console.log("fetch review");
        await fetchReview(user?.token, dispatch, `reviews/get-review/${id}`);
    }
    const handleFetchReward = async () => {
        var fullReview = await fetchReward(dispatch, `rewards/get-reward-basics/${reviewToVerify.rewardUsed}`);
        setcurrentReview(fullReview)
        console.log(fullReview);
    }

    const handleUseReward = async () => {
        await updateReview(user?.token, dispatch, `reviews/update-review`, {"rewardIsValid": false, _id: reviewToVerify._id});
    }

    useEffect(() => {
        if(Object.keys(reviewToVerify).length == 0) {
            handleFetchReview();
        }
    })
    useEffect(() => {
        if(Object.keys(reviewToVerify).length !== 0) {
            handleFetchReward();

            var expirationDate = moment(reviewToVerify?.availableFrom).add(1, 'M').format()
            setexpirationDate(expirationDate)
        }
    }, [reviewToVerify])

    var seo = {
        title: "Venco | Vérification de la récompense client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">

                <div className="content-container verify-reward">
                    <h2 className='scan-title'>Scannez la récompense client</h2>
                    <p className="scan-subtitle">Scannez les QRcode de récompense de vos clients ici. Vous pouvez également utiliser l'application photo de votre téléphone pour faciliter cette tâche. N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>


                    <h2 className="content-container-title">Nom/pseudo client : <span>{reviewToVerify.reviewerName}</span></h2>
                    <h2 className="content-container-title">Récompense client : <span>{currentReview.reward}</span></h2>
                    {/* <h2 className="content-container-title">Rareté de la récompense : {currentReview.rarity}</h2> */}
                    <h2 className="content-container-title">Récompense valide : <span>{reviewToVerify.rewardIsValid && moment().isAfter(reviewToVerify?.availableFrom) && moment().isBefore(expirationDate) ? "Oui" : "Non"} {!reviewToVerify.rewardIsValid ? "(Récompense déjà utilisé)" : moment().isAfter(reviewToVerify?.availableFrom) && reviewToVerify?.availableFromDate && moment().isBefore(expirationDate) ? `(Récompense valable à partir de ${reviewToVerify?.availableFromDate})` : moment().isAfter(expirationDate) ? "Récompense périmé" :  ""}</span></h2>

                    <button onClick={() => { handleUseReward() }}>Valider l'utilisation de la récompense {!reviewToVerify.rewardIsValid || moment().isBefore(reviewToVerify?.availableFrom) ? "quand même" : ""}</button>
                </div>

            </div>
        </div>
    )
}

export default Verifier