import React, { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { ClientLogin } from '../../redux/clientSlice';
import { Navigate, useParams, useLocation } from "react-router-dom";
import { fetchPlaceBasics } from '../../utils';
import ClientTopHeader from "../../components/ClientTopHeader/ClientTopHeader"

import './style.scss';

const ClientLoginGoogle = () => {
    const { client } = useSelector((state) => state.client);
    const { currentPlace, editCurrentPlace } = useSelector((state) => state.client);
    const location = useLocation();
    let { id } = useParams()

    const dispatch = useDispatch();

    const handleCallbackResponse = (response) => {
        var userObject = jwtDecode(response.credential)
        dispatch(ClientLogin(userObject))
        // dispatch(GetLastReviews())
    };

    const fetchPlace = async () => {
        await fetchPlaceBasics(dispatch, `/places/get-place-basics/${id}`);
    };

    useEffect(() => {
        if (Object.keys(currentPlace).length == 0) {
            fetchPlace();
        }  

        /* global google */
        google.accounts.id.initialize({
            client_id: "657917244428-2bcjap48iujc3sn5ofnbgt56fvcaj92c.apps.googleusercontent.com",
            callback: handleCallbackResponse
        })

        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { 
                theme: "outline",
                // size: "large",
                text: "signin_with",
                shape: "pill",
                locale: "fr_FR",
                width: `${document.querySelector('.home-bottom-ctas').clientWidth}px`
            }
        )

        google.accounts.id.prompt();
    }, [])


    return (
        <div className={`home`}>
            <div className="home-top" style={{backgroundImage: `url('${currentPlace?.image}'`}}>
                <ClientTopHeader />
            </div>
            <div className="home-bottom">
                <div className="home-bottom-content">
                    <h1>Connectez vous a votre compte Google</h1>
                    <h4>Connectez vous afin que nous puissions vérifier votre avis.</h4>
                    {Object.keys(client).length !== 0 && (
                        <div className="test">
                            <Navigate to={location.pathname.replace("login-google", "reward")} />
                        </div>
                    )}
                </div>
                <div className="home-bottom-ctas">
                    <div id="signInDiv"></div>
                </div>
            </div>
        </div>
)
}

export default ClientLoginGoogle