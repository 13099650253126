import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchPlaceUi } from '../../utils';

import './style.scss';

const ClientTopHeader = () => {
    const dispatch = useDispatch();
    const [currentLocationUi, setcurrentLocationUi] = useState({});
    const { currentPlace } = useSelector((state) => state.client);

    useEffect(() => {
        if(Object.keys(currentPlace).length !== 0 && currentPlace.customUi.length !== 0) {
            toggleFetchPlaceUi(currentPlace.customUi);
        }
    }, [currentPlace])
    const toggleFetchPlaceUi = async (id) => {
        var placeUi = await fetchPlaceUi(dispatch, id);
        setcurrentLocationUi(placeUi);
    };

    return (
        <>
            <p className="landing-top-title">{currentLocationUi?.placeName ? currentLocationUi?.placeName : currentPlace?.name}</p>
            <p className="landing-top-desc">{currentLocationUi?.placeDescription ? currentLocationUi?.placeDescription : "Recevez une récompense pour votre avis !"}</p>
        </>
    )
}

export default ClientTopHeader