import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import moment from 'moment';

import { useForm } from "react-hook-form";
import TopBar from '../../components/TopBar/TopBar';
import { getUserInfo, fetchPlaces, fetchRewards, deleteReward } from '../../utils';
import { UserLogin } from '../../redux/userSlice'
import Loading from '../../components/Loading/Loading';
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import LeftNav from "../../components/LeftNav/LeftNav";
import TopData from "../../components/TopData/TopData";

import './style.scss';

const RewardsTable = ({isCurrentLocation = false}) => {
    const { user, editUser } = useSelector((state) => state.user);
    const { places, editRewards } = useSelector((state) => state.place);
    const { rewards, editplaces } = useSelector((state) => state.reward);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    if (Object.keys(rewards).length) {
        var draftRewardsCount = 0
        var liveRewardsCount = 0
        var offRewardsCount = 0

        rewards.map((reward) => {
            if (reward.status == "draft") {
                draftRewardsCount++;
            } else if (reward.status == "live") {
                liveRewardsCount++;
            } else {
                offRewardsCount++;
            }
        })
    }

    const handleDeleteReward = async (e) => {
        var placeName = "";
        places.map((place) => {
            if (place._id == e.placeId) {
                placeName = place.name;
            }
        })
        if(placeName !== "") {
            await deleteReward(user?.token, dispatch, `rewards/delete-reward/${e._id}`, {"placeId": e.placeId});
        } else {
            await deleteReward(user?.token, dispatch, `rewards/delete-reward/${e._id}`, {"placeId": ""});
        }
    }

    const toggleMorePannel = (e) => {
        e.target.closest(".content-container-section-table-card").querySelector(".learnMore").classList.toggle("active");
    }

    return (
        <div className="content-container-section content-container-section_reward">
            <div className="content-container-section-top">
                <h2 className="content-container-section-top-title">Vos récompenses</h2>
                {!isCurrentLocation && <Link className='content-container-section-top-cta' to={"/recompenses"}>Voir tout</Link>}
            </div>
            <div className="content-container-section-tables">
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Brouillon</p>
                        <span className='content-container-section-table-top-count'>{draftRewardsCount ? draftRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(rewards).length !== 0 && Object.keys(places).length !== 0) && rewards.map((reward, i) => {
                            if (reward.status !== "draft") {
                                return;
                            }

                            var updatedAt = new Date(reward.updatedAt);
                            var placeName = "";
                            
                            places.map((place) => {
                                if (place._id == reward.placeId) {
                                    placeName = place.name;
                                }
                            })

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{reward.reward}</h4>
                                    <p className="content-container-section-table-card-location"><span>Lieux : </span>{placeName !== "" ? placeName : "aucun"}</p>
                                    <p className="content-container-section-table-card-location"><span>Rareté : </span>{reward.rarity}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/recompenses/modifier/" + reward._id} className="learnMore-item">Modifier</Link>
                                        <span onClick={() => {handleDeleteReward(reward)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom content-container-section-table-bottom_draft">
                        <Link to={"/recompenses/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une récompense</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>En ligne</p>
                        <span className='content-container-section-table-top-count'>{liveRewardsCount ? liveRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(rewards).length !== 0 && Object.keys(places).length !== 0) && rewards.map((reward, i) => {
                            if (reward.status !== "live") {
                                return;
                            }

                            var updatedAt = new Date(reward.updatedAt);
                            var placeName = "";
                            
                            places.map((place) => {
                                if (place._id == reward.placeId) {
                                    placeName = place.name;
                                }
                            })

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{reward.reward}</h4>
                                    <p className="content-container-section-table-card-location"><span>Lieux : </span>{placeName}</p>
                                    <p className="content-container-section-table-card-location"><span>Rareté : </span>{reward.rarity}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/recompenses/modifier/" + reward._id} className="learnMore-item">Modifier</Link>
                                        <span onClick={() => {handleDeleteReward(reward)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">
                        <Link to={"/recompenses/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une récompense</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Désactivé</p>
                        <span className='content-container-section-table-top-count'>{offRewardsCount ? offRewardsCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {(Object.keys(rewards).length !== 0 && Object.keys(places).length !== 0) && rewards.map((reward, i) => {
                            if (reward.status !== "off") {
                                return;
                            }

                            var updatedAt = new Date(reward.updatedAt);
                            var placeName = "";
                            
                            places.map((place) => {
                                if (place._id == reward.placeId) {
                                    placeName = place.name;
                                }
                            })

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{reward.reward}</h4>
                                    <p className="content-container-section-table-card-location"><span>Lieux : </span>{placeName}</p>
                                    <p className="content-container-section-table-card-location"><span>Rareté : </span>{reward.rarity}</p>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span onClick={(e) => { toggleMorePannel(e) }} className='content-container-section-table-card-more'>...</span>

                                    <div className="learnMore">
                                        <Link to={"/recompenses/modifier/" + reward._id} className="learnMore-item">Modifier</Link>
                                        <span onClick={() => {handleDeleteReward(reward)}} className="learnMore-item">Supprimer</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">                        
                        <Link to={"/recompenses/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une récompense</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardsTable