import React, { useState } from 'react';

import './style.scss';

const SelectInput = React.forwardRef(
    (
        { type, placeholder, style, label, register, name, error, value, values}, 
        ref
    ) => {
        return (
            <div className='text-input'>
                {label && <p className="text-input-label">{label}</p>}
                <select className={`${style}`} id="status" defaultValue={value ? value : ""} type={type} name={name} placeholder={placeholder} ref={ref} {...register} aria-invalid={error ? "true" : "false"}>
                    {Object.keys(values).length !== 0 && values?.map((option) => {
                        return <option selected={option?.id == value ? true : false} value={typeof option.id !== "undefined" ? option.id : option._id}>{option.name}</option>;
                    })}
                </select>
                <span className={"text-inut-error " + error !== '' ? 'isVisible' : ''}>{error}</span>
            </div>
        )
    }
)

export default SelectInput;